<template>
    <div>
          <div slot="header" class="clearfix">
            <div style="margin:15px;">
            <el-input size="small" placeholder="输入文件名查找"  v-model.trim="selName" style="width:150px;"></el-input>
            <el-button size="small"  @click="selFileNameLog" type="primary">查找</el-button>
            <el-button size="small"  @click="getSelOption" type="primary" style="margin-left:10px">刷新</el-button>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,30,50]"
                :page-size="30"
                layout="total, sizes, prev, pager, next, jumper"
                style="float:right"
                :total="total">
          </el-pagination>
          </div>
          </div>
            <el-table border
             class="tabelBox" 
             v-loading="loading"
             :data="upActionLs.filter(data => !search || data.fileName.includes(search))"
              size="small">
           <el-table-column prop="id"  label="序号" width="100">
           </el-table-column>
           <el-table-column prop="fileName"  label="文件名称" >
            <template slot="header" slot-scope="scope">
               <el-input placeholder="快速查找" v-model="search"  size="mini">
                  <template slot="prepend">文件名</template>
               </el-input>
              </template> 
              <!--showDetail-->
               <template slot-scope="scope">
                  <span @click="showDetail(scope.row)"><a href="#"> {{ scope.row.fileName }}</a></span>
               </template>
              
           </el-table-column>
           <el-table-column  prop="createdTime"  label="上传时间" width="200"></el-table-column>
           <el-table-column prop="adminName" label="操作人"> 
           </el-table-column>
           <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-tag :type="(scope.row.status === 1)?'success':'warning'" size="small"> {{ (scope.row.status === 1)?"成功:"+scope.row.remark:"失败~"+scope.row.remark }}</el-tag>
            </template>
           </el-table-column>
           <el-table-column prop="usersHospitalId" label="数据列表ID" width="120">
           </el-table-column>
           <el-table-column prop="operation" label="操作" width="120">
            <template slot-scope="scope">
             <el-popconfirm title="你确定删除该文件吗？" @confirm="deleteRow(scope.$index,scope.row.id,upActionLs)">
                         <el-button slot="reference" type="text" size="small"  > 删除 </el-button>
                    </el-popconfirm>
                    </template>
           </el-table-column>
           </el-table>
            <el-drawer
                :title="fileName"
                :visible.sync="dialogVisible"
                 size="55%"
              >
                  <pre>
                     <p> {{ fileNameDetail }} </p> 
                  </pre>
            </el-drawer>
    </div>
</template>
<script>
import { getAdminLog,delfileLog } from '@/api/fileLog';
 export default {
    data(){
        return {
        upActionLs:[],
        selName:'',// 文件名查找
        search:'',
        token:sessionStorage.getItem('token'),
        pageNo:1,
        pageSize:30,
        total:0,
        loading:false,
        dialogVisible: false,
        fileNameDetail:'',
        fileName:'',
        }
    },
    methods:{
        async getList(opt){
        this.loading = true
        const res = await getAdminLog(opt);
        if(res.code===200){
          this.loading = false;
          this.upActionLs = res.rows;
          this.total = res.total;
        }
      },
      selFileNameLog(){
        const name = this.selName;
        if(name.length==0){
          this.$message.warning("输入要查询的文件名");
          return;
        }
        const opt = {
          fileName:name,
          // pageSize:this.pageSize,
          // pageNo:this.pageNo
        }
        this.getList(opt);
      },
      
      handleSizeChange(val){   
        this.pageSize = val; 
        this.getSelOption();
      },
      handleCurrentChange(val){
        this.pageNo = val;
        this.getSelOption();
      },
      getSelOption(){
        const opt = {
          pageSize:this.pageSize,
          pageNo:this.pageNo,
        }
        this.getList(opt);
      },
     async deleteRow(index,id,rows){
       const res = await delfileLog(id);
       if(res.code === 200 ){
          rows.splice(index, 1);
          this.total = this.total -1;
          this.$message.success("删除成功");
        }else{
          this.$message.error("删除有误");
        }
      },
      showDetail(row){
         this.dialogVisible = true;
         this.fileName = row.fileName;
         this.fileNameDetail = JSON.stringify(JSON.parse( row.originalData),null,"\t") ;
      }
    },
    created(){
      this.getSelOption();
    }
    
 }
</script>