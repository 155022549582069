import request from '../utils/request'
import qs from 'qs';
// 操作日志信息
export function getAdminLog(params) {
    return request({
      url: '/file/list',
      method: 'get',
      params,
      paramsSerializer:params=>{
        return qs.stringify(params,{indices:false})
      },
    });
  }

  export function delfileLog(id) {
    return request({
      url: `/file/${id}`,
      method: 'delete',
      data:id
    });
  }
  